import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I am currently building `}<a parentName="p" {...{
        "href": "https://findcommunities.today"
      }}>{`Find Communities Today`}</a>{`, a place where you can find communities online. I wanted to allow users to submit their favourite community so I added a page with a form that users could fill out and submit.`}</p>
    <p>{`Initially, I was using an `}<inlineCode parentName="p">{`<iframe>`}</inlineCode>{` with the form created by Airtable, but the page would take ages to load so I decided to try and make a form myself and sent it to Airtable.`}</p>
    <h1 {...{
      "id": "what-will-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#what-will-you-need",
        "aria-label": "what will you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What will you need`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://airtable.com/invite/r/abr1Wgbb"
        }}>{`Airtable Account`}</a>{` - This is my referal link.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.netlify.com"
        }}>{`Netlify Account`}</a>{` - I'm using environmental variables with Netlify`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gatsbyjs.org/docs/environment-variables/"
        }}>{`Gatsby`}</a>{` - Gatsby handles environmental variables automatically.`}</li>
      <li parentName="ul">{`.env folder - I am going to mention environmental variables`}</li>
    </ul>
    <p>{`This example will be quite simple. Find Communities Today is being served by Netlify directly from a private repo of GitHub. I am already using Netlify forms for people to report problems with the details of a community, so I didn't want to use Netlify to handle this form.`}</p>
    <p>{`Also, Airtable is pretty awesome when you need to see the data in a logical way. Since I had created the base in my Airtable, I decided to keep using it to handle the submission of the communities.`}</p>
    <h1 {...{
      "id": "setting-up-airtable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#setting-up-airtable",
        "aria-label": "setting up airtable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up Airtable`}</h1>
    <p><a parentName="p" {...{
        "href": "https://airtable.com/invite/r/abr1Wgbb"
      }}>{`Sign up to Airtable`}</a>{` if you haven't done so and then log in. There are a lot of templates that you can use, but we will start with a brand new one.`}</p>
    <p>{`Press the button `}<inlineCode parentName="p">{`+ Add a Base`}</inlineCode>{` and from the dropdown menu choose `}<inlineCode parentName="p">{`start from scratch`}</inlineCode>{`, choose a name and press `}<inlineCode parentName="p">{`Enter`}</inlineCode>{`. Airtable will create a spreadsheet with three columns`}</p>
    <ul>
      <li parentName="ul">{`Name`}</li>
      <li parentName="ul">{`Notes`}</li>
      <li parentName="ul">{`Attachments`}</li>
    </ul>
    <p>{`Fill the first row with some random text and then on the bottom right corner look for the yellow icon with three dots. Click it and choose `}<inlineCode parentName="p">{`API Documentation`}</inlineCode></p>
    <p>{`Then scroll down until you are on the `}<inlineCode parentName="p">{`Create record`}</inlineCode>{`. On the right, you can see the curl call. This will help when we build the API call when submitting the form.`}</p>
    <p>{`Finally, you will need your API key. Go to your `}<a parentName="p" {...{
        "href": "https://airtable.com/account"
      }}>{`Airtable Account`}</a>{` and click on Generate API key, click the input field so you can see the key and copy it.`}</p>
    <h1 {...{
      "id": "setting-up-the-site",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#setting-up-the-site",
        "aria-label": "setting up the site permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up the site`}</h1>
    <p>{`If you have never used Gatsby I'd recommend that you read the great tutorial on the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/quick-start"
      }}>{`official Gatsby site`}</a>{`.`}</p>
    <p>{`Install Gatsby Cli with the command`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install --global gatsby-cli
`}</code></pre>
    <p>{`Then run this command to create a new site and change directory into the new folder.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gatsby new gatsby-site && cd gatsby-site
`}</code></pre>
    <p>{`Finally, open the folder on your favourite code editor and run the following command to get your site running.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gatsby develop
`}</code></pre>
    <h1 {...{
      "id": "create-an-environmental-variable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#create-an-environmental-variable",
        "aria-label": "create an environmental variable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create an Environmental Variable`}</h1>
    <p>{`If you are using Netlify, you can just head over to `}<inlineCode parentName="p">{`Build & Deploy`}</inlineCode>{` tab and scroll down until you find the "Build environment variables", make sure your environmental variable starts with `}<inlineCode parentName="p">{`GATSBY_<name>`}</inlineCode>{` so you can store your API key on a variable with the name `}<inlineCode parentName="p">{`GATSBY_AIRTABLE`}</inlineCode>{` for example.`}</p>
    <p>{`If you just want to test this with Gatsby on your development version, you can just create the file `}<inlineCode parentName="p">{`.env.development`}</inlineCode>{` on the root of your project and then add the API key from airtable like this: `}<inlineCode parentName="p">{`AIRTABLE_API=<your API key here>`}</inlineCode>{`.`}</p>
    <p>{`Then, when you want to use your API key all you need to do is type `}<inlineCode parentName="p">{`process.env.AIRTABLE_API`}</inlineCode>{` and Gatsby will automatically translate that into your API key.`}</p>
    <h1 {...{
      "id": "build-the-form",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#build-the-form",
        "aria-label": "build the form permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Build the form`}</h1>
    <p>{`Let's finally build the form. Create a new page on your `}<inlineCode parentName="p">{`pages`}</inlineCode>{` folder, call it `}<inlineCode parentName="p">{`contact`}</inlineCode>{` and add the following code to the page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react"

class Contact extends React.component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = e => {
    console.log(this.state);

    e.preventDefault();
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value})

  render() {
    return() (
      <form>
        <label>
          Name
          <input type="text" name="name" onChange={this.handleChange} />
        </label>
        <label>
          Notes
          <input type="text" name="notes" onChange={this.handleChange} />
        </label>
        <button type="submit">Submit</button>
      </form>
    )
  }
}
`}</code></pre>
    <p>{`Head over to `}<a parentName="p" {...{
        "href": "http://localhost:8000/contact"
      }}>{`http://localhost:8000/contact`}</a>{`, you will see the quite ugly form that we have created. Open devtools and then fill the form, you will see that whatever you just typed on the input fields will be logged into the console.`}</p>
    <p>{`As you can see, our component is keeping track of what is being written to the input fields. We can now call Airtable API and send this to our base.`}</p>
    <h1 {...{
      "id": "submit-to-airtable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#submit-to-airtable",
        "aria-label": "submit to airtable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Submit to Airtable`}</h1>
    <p>{`The API post request will be done in the `}<inlineCode parentName="p">{`handleSubmit`}</inlineCode>{` method. Make sure you are on the `}<em parentName="p">{`Create a record`}</em>{` in the API Documentation because you will need to know the URL to send the post request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`handleSubmit = e => {
  const fields = {"fields": {"Name": this.state.name, "Notes": this.state.notes}}
  fetch("https://api.airtable.com/v0/<account id>/<table name>", {
    method: "POST",
    headers: {"Authorization": \`Bearer \${process.env.AIRTABLE_API}\`,
              "Content-Type": "application/json"},
    body: JSON.stringify(fields)
  })
  .then(() => alert("Form Sent!"))
  .catch(error => alert(error))

  e.preventDefault();
}

`}</code></pre>
    <p>{`Notice that I am using backticks on the Authorization header if you want you can just write it like this: `}<inlineCode parentName="p">{`"Bearer "  + process.env.AIRTABLE_API"`}</inlineCode>{`. Also, make sure you are passing the environmental variable with the exact name as on your `}<inlineCode parentName="p">{`.env.development`}</inlineCode>{` file.`}</p>
    <h1 {...{
      "id": "full-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#full-code",
        "aria-label": "full code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full code`}</h1>
    <p>{`So the full working code will look like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react"

class Contact extends React.component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmit = e => {
    const fields = {"fields": {"Name": this.state.name, "Notes": this.state.notes}}
    fetch("https://api.airtable.com/v0/<account id>/<table name>", {
      method: "POST",
      headers: {"Authorization": \`Bearer \${process.env.AIRTABLE_API}\`,
                "Content-Type": "application/json"},
      body: JSON.stringify(fields)
    })
    .then(() => alert("Form Sent!"))
    .catch(error => alert(error))

    e.preventDefault();
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value})

  render() {
    return() (
      <form>
        <label>
          Name
          <input type="text" name="name" onChange={this.handleChange} />
        </label>
        <label>
          Notes
          <input type="text" name="notes" onChange={this.handleChange} />
        </label>
        <button type="submit">Submit</button>
      </form>
    )
  }
}
`}</code></pre>
    <p>{`If you are using Netlify don't forget to change the environmental variable to `}<inlineCode parentName="p">{`GATSBY_<your env variable name>`}</inlineCode>{`.`}</p>
    <p>{`Note that you will need to change the form to use it in production, you probably want to change the input names, the airtable columns names and also create a honeypot to avoid bots from spamming your Airtable base. This was meant to be a quick and simple way to use the Airtable API to submit a form.`}</p>
    <p>{`Let me know what you think about this. Also, let me know if there is a better way to do this or if you have any issue when trying the code!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      